import {Slot} from '@radix-ui/react-slot';
import {useId} from 'react';
import {cn} from '../utils';
import {Label} from './label';
import {Skeleton} from './skeleton';

type FormSectionProps = {
  label?: string;
  rightLabel?: string;
  error?: string;
  className?: string;
  labelClassName?: string;
  loading?: boolean;
  orientation?: 'horizontal' | 'horizontal-left-right' | 'vertical' | 'horizontal-reverse';
} & React.ButtonHTMLAttributes<HTMLDivElement>;

export const FormSection = ({
  label,
  rightLabel,
  labelClassName,
  error,
  className,
  children,
  loading,
  orientation = 'vertical',
  ...props
}: FormSectionProps) => {
  const id = useId();
  if (loading) {
    return (
      <div className={cn(className, 'flex')} {...props} title={props.title ? props.title : label}>
        {label && <Skeleton className={cn('w-36 rounded-full mb-2 h-6')} />}
        <Skeleton className="h-12 rounded-xl " />
      </div>
    );
  }

  return (
    <div
      className={cn(
        'group m-0 gap-1 flex',
        className,
        orientation === 'horizontal' || orientation === 'horizontal-left-right'
          ? 'flex-row items-center min-h-10'
          : orientation === 'horizontal-reverse'
            ? 'items-center flex-row-reverse justify-end min-h-10'
            : 'flex-col',
      )}
      {...props}>
      {label && (
        <Label
          className={
            orientation !== 'vertical' ? (orientation === 'horizontal-left-right' ? undefined : 'flex-1') : undefined
          }
          htmlFor={id}>
          {label}
        </Label>
      )}
      <Slot id={id}>{children}</Slot>
      {rightLabel && orientation === 'horizontal-left-right' && <Label>{rightLabel}</Label>}
      {error && <div className="float-right pt-0.5 h-2 text-destructive -mb-2.5">{error}</div>}
    </div>
  );
};
